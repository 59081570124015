import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { WellPageContent } from '~/components/forms/Well';
import { withVerifyEmailSource } from '~/HOC/VerifyEmailSource';

const WellPage: React.FC<PageProps> = props => (
  <>
    <SEO pageContext={props.pageContext} title="WellForm.pageTitle" />
    <WellPageContent {...props} />
  </>
);

export default withVerifyEmailSource(WellPage);
