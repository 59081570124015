import React, { useEffect } from 'react';
import { Demographics } from '../Demographics';
import { useTranslation } from 'react-i18next';
import { getUrlParam } from '~/common/urlParams';
import { ReportType } from '~/context/global/reports';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useWellReportSubmission } from '~/state/hooks/useWellReportSubmission';
import { getDefaultDemographicsFormValues } from '~/components/forms/helpers';
import { useUserDemographics } from '~/state/hooks/useUserDemographics';
import { useNavigate } from '~/state/hooks/useNavigate';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useGlobalState } from '~/context/global';
import { LoadingPage } from '~/components/Loading';
import { logger, ONMFeature } from '~/services/logging';
import { useSelector } from '~/store';
import { PageProps } from 'gatsby';
import { isEmpty } from 'lodash';

export const WellPageContent: React.FC<PageProps> = props => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { submitReport } = useWellReportSubmission();
  const { account } = useGlobalState().user;
  const { skipDemographicsForm } = useUserDemographics();
  const { loggedIn } = useSelector(state => state.userAuth);
  const comingFromReminderEmail = getUrlParam(props.location, 'emailSrc');

  const submitWellReport = async () => {
    try {
      const report = getDefaultDemographicsFormValues({}, account);
      const recaptchaToken = await executeRecaptcha?.('well');
      if (!recaptchaToken) {
        throw Error(t('ErrorHandling.recaptcha'));
      }
      const response = await submitReport([report], recaptchaToken);
      navigate('bonus-questions', {
        replace: true,
        state: { wellSubmissionId: response.userSubmissionId },
      });
    } catch (error) {
      logger.error(ONMFeature.INDIVIDUAL_REPORT_FROM_EMAIL, error);
      const errorCode = error?.response?.data?.errorCode ?? 'ServerError';
      showSnackbar(t(`ErrorHandling.submissionErrors.${errorCode}`), 'error');
    }
  };

  useEffect(() => {
    if (comingFromReminderEmail && skipDemographicsForm) {
      submitWellReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipDemographicsForm]);

  if (
    (loggedIn && isEmpty(account)) ||
    (comingFromReminderEmail && skipDemographicsForm)
  ) {
    return <LoadingPage />;
  }

  return <Demographics reportType={ReportType.WELL} {...props} />;
};
